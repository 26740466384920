
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
@import '@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss';

.itg-home-pane__info {
  min-height: 16rem;
  height: 100%;
  background-size: cover;
  background-position: top;
  position: relative;
  padding: 4rem 2rem;

  &__heading {

    &__title {
        font-size: 2.4rem;
        text-align: center;
        color: $itg-white;
        font-weight: $itg-font-weight-bold;

        @media (max-width: $screen-lg) {
            font-size: 2rem;
        }

        @media (max-width: $screen-sm) {
            font-size: 1.6rem;
        }
    }

    padding: 0.5rem 0rem;

    &__center {
      padding-top: 1rem;
    }
  }

  &__description {
    color: $itg-white;
    font-size: $mobile-body-font-size-med;
    margin: 0 auto;
    text-align: center;
    font-weight: 600;

    @media (min-width: $screen-xl) {
      font-size: $mobile-body-font-size-max;
    }
  }

  &__linebreak-title {
    display: none;

    @media (max-width: $screen-sm) {
      display: block;
    }
  }
}

.itg-home-pane-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}