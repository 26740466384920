
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-logo {
    height: 3rem;
    // These props ensure logo works nicely with Image tag.
    position: relative;
    left: 0;
    top: 0;
    min-width: 10rem;

    @media (max-width: $screen-sm) {
        height: 2.6rem;
    }
}

.hamburger-icon {
    color: $itg-black;
}

.itg-header-hamburger {
    float: left;
    margin: .1rem;
    cursor: pointer;

    &:hover{
        color: $itg-orange;
    }
    &:focus {
        color: $itg-black;
    }

    i {
        font-size: 1.3rem;
    }

    svg {
        height: 2rem;
        width: 2rem;
        margin-right: 0.2rem;
    }
}

.itg-header-side-section {
    display: flex;
    justify-content: center;

    // Less than width xl, we no longer show the listed out
    // navigation items (e.g. <HomeMenu/>) and we opt to show
    // the hamburger, thus, width no longer requires a value
    // for centering.
    @media (max-width: $screen-xl) {
        width: unset;
    }
}

.itg-header-side-section.leftmost {
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;

    // On smaller screens no need for margin left or right on side sections.
    @media (max-width: $screen-md) { margin-right: unset; }

    a {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.itg-header-side-section.rightmost {
    justify-content: flex-end;
    align-items: center;
    margin-left: 2rem;

    a {
        color: black;
    }

    div {
        margin-left: .2rem;
    }

    @media (max-width: $screen-md) { margin-left: unset; }
}

// For when we want to underlay content under the header...
.itg-header-transparent {
    // Because we want to override styles defined in the header id, "itg-header" we need to
    // add !important because class styles have lesser prioritt than id styles.
    background-color: transparent !important;
    border-bottom-color: transparent !important;
}

// Gives us the choice of inverting the colours in our header.
.itg-header-inverted {
    // Same as noted in "itg-header-transparent"
    color: white !important;

    a {
        color: white !important;
    }

    // Easy way to update svg color since the svg has "fill: currentColor"
    svg {
        color: white !important;
    }

    // Updates Svg with multiple <path>
    svg path {
        fill: white !important;               
    }
}