
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-body-no-margin {
    margin-top: 0rem !important;

    min-height: calc(100vh - $desktop-footer-height) !important;

    // Footer changes at medium screen so we must account for that.
    @media (max-width: $screen-md) { min-height: calc(100vh - $mobile-footer-height) !important;}
}