
        @import "@infotrack/infotrackgo.web.core/styles/partials/_variables.scss";
        @import "@infotrack/infotrackgo.web.core/styles/partials/_mediaqueries.scss";
        @import "@infotrack/infotrackgo.web.core/styles/fonts.scss";
        @import "@infotrack/infotrackgo.web.core/styles/layout.scss";
        
.itg-shopping-cart {
    cursor: pointer;
    float: right;
    margin: 0.4rem;

    @media (max-width: $screen-xs) {
        margin-right: 1rem;
    }

    :global(.MuiBadge-badge) {
        background-color: $itg-orange;
        color: white;
    }

    &__icon {
        font-size: 2rem;

        @media (max-width: $screen-xs) {
            font-size: 2.2rem;
            height: 2.5rem;
        }
    }
}